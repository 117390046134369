import moment from "moment";

export default class DashboardInstantController {
  constructor(
    $state,
    $scope,
    $interval,
    $timeout,
    UIService,
    DashboardService,
    AuthenticationService
  ) {
    this.Dashboard = DashboardService;
    this.UI = UIService;

    this.user = AuthenticationService.getUser();

    $scope.$on("$inlineHelpUpdate", () => {
      this.user = AuthenticationService.getUser();
    });

    if (!$scope.$$destroyed) {
      this.interval = $interval(() => {
        this.consumptionLoaded = false;
        this.solarLoaded = false;
        this.updateConsumptionData();
        this.updateSolarData();
      }, 0.5 * 1000 * 60); // 30 em 30 sec
    }
    $scope.$on("$destroy", () => {
      if (this.interval) $interval.cancel(this.interval);
    });

    $scope.$watch(
      () => this.day,
      () => {
        this.getLast24hrs();
      }
    );

    this.dayOptions = {
      format: "DD/MM/YYYY",
      maxDate: moment.utc(),
    };

    this.day = moment.utc().startOf("d");
    this.getData();
  }

  isToday = () => moment.utc().isSame(this.day, "day");

  setFractionFor = (chart) => (chart.value > 10 ? 0 : 3);

  getData = () => {
    this.deprecatedError = false;
    this.consumptionLoaded = false;
    this.solarLoaded = false;
    this.hasSolar = false;
    this.hasConsumption = false;
    this.Dashboard.registeredPotency().then((r) => {
      if (r) {
        // Cria data para consumo
        this.hasConsumption = r.potency.grid.enabled;
        this.createConsumptionGauges([r]);
        // Cria data para solar
        this.hasSolar = r.potency.production.enabled;
        this.createSolarGauges([r]);
      } else {
        this.deprecatedError = true;
      }
    });
  };

  updateConsumptionData = () => {
    this.consumption[0].value = 0;
    this.consumption[0].time = null;
    this.consumption[0].loaded = false;
    this.consumption[0].error = false;
    this.consumption.forEach((p) => {
      if (p.origin) {
        p.loaded = false;
        p.error = false;
        this.Dashboard.instant(this.user.selected.siteId, p.origin).then(
          (response) => {
            let value = Number(response.value) / 1000; // W to kW
            p.time = moment.unix(response.time);
            let timeCheck = Math.abs(moment().diff(p.time, "hour"));
            if (timeCheck > 0) {
              // Com erro
              p.loaded = true;
              p.error = true;
              p.type = "Leitura inválida";
              this.consumption[0].loaded = true;
              this.consumption[0].error = true;
            } else {
              if (p.other) {
                this.Dashboard.instant(this.user.selected.siteId, p.other).then(
                  (response) => {
                    p.value2 = Number(response.value) / 1000; // W to kW
                    p.value2 *= p.operation;
                    value += p.value2;
                    p.value = value;
                    p.loaded = true;
                    this.consumption[0].value += p.value;
                    this.consumption[0].loaded = true;
                  }
                );
              } else {
                p.value = value;
                p.loaded = true;
                this.consumption[0].value += p.value;
                this.consumption[0].loaded = true;
              }
            }
          }
        );
      } else {
      }
    });
    this.consumptionLoaded = true;
  };

  updateSolarData = () => {
    this.solar[0].value = 0;
    this.solar[0].time = null;
    this.solar[0].loaded = false;
    this.solar.forEach((p, i) => {
      if (p.origin) {
        p.loaded = false;
        p.error = false;
        this.Dashboard.instant(this.user.selected.siteId, p.origin).then(
          (response) => {
            p.value = Number(response.value) / 1000; // W to kW
            p.time = moment.unix(response.time);
            let timeCheck = Math.abs(moment().diff(p.time, "hour"));
            if (timeCheck > 0) {
              // Com erro
              p.loaded = true;
              p.error = true;
              p.type = "Leitura inválida";
              this.solar[0].loaded = true;
              this.solar[0].error = true;
            } else {
              this.solar[0].value += p.value;
              if (i == this.solar.length - 1) {
                this.solar[0].value -= p.value * 2;
              }
              p.loaded = true;
              this.solar[0].loaded = true;
            }
          }
        );
      }
    });
    this.solarLoaded = true;
  };

  createConsumptionGauges = (r) => {
    this.consumption = [];
    r.forEach((p) => {
      let t = 0;
      if (p.potency) {
        let capacity = 0;
        // Rede
        if (p.potency.grid.enabled) {
          this.consumption.push({
            origin: "grid",
            name: "Rede",
            label: "",
            capacity: p.potency.grid.capacity / 1000,
            time: moment(),
            color: "#AA3033",
            value: 0,
            tooltip: "Consumo de energia elétrica proveniente da rede elétrica",
          });
          capacity += p.potency.grid.capacity;
        }
        // Solar
        if (p.potency.production.enabled) {
          this.consumption.push({
            origin: "production",
            other: p.potency.return.enabled ? "return" : undefined,
            operation: -1,
            name: "Solar",
            label: "",
            capacity: p.potency.production.capacity / 1000,
            time: moment(),
            color: "#FDA50F",
            value: 0,
            tooltip:
              "Consumo de energia elétrica proveniente dos painéis solares",
          });
          capacity += p.potency.production.capacity;
        }
        this.consumption.unshift({
          name: "Total",
          label: "",
          capacity: capacity / 1000,
          time: moment(),
          color: "#01419a",
          value: 0,
          tooltip: "Consumo de energia elétrica total na instalação",
        });
      }
    });
    this.updateConsumptionData();
  };

  createSolarGauges = (r) => {
    this.solar = [];
    r.forEach((p) => {
      let t = 0;
      if (p.potency) {
        let capacity = 0;
        // Rede
        if (p.potency.production.enabled) {
          this.solar.push({
            origin: "production",
            name: "Consumido",
            label: "",
            capacity: p.potency.production.capacity / 1000,
            time: moment(),
            color: "#AA3033",
            value: 0,
            tooltip:
              "Consumo de energia elétrica proveniente dos painéis solares",
          });
          capacity += p.potency.production.capacity;
        }
        // Solar
        if (p.potency.return.enabled) {
          this.solar.push({
            origin: "return",
            name: "Excedente",
            label: "",
            capacity: p.potency.return.capacity / 1000,
            time: moment(),
            color: "#512DA8",
            value: 0,
            tooltip:
              "Energia elétrica não consumida proveniente dos painéis solares",
          });
          capacity += p.potency.return.capacity;
        }
        this.solar.unshift({
          name: "Total",
          label: "",
          capacity: capacity / 1000,
          time: moment(),
          color: "#01419a",
          value: 0,
          tooltip: "Total de energia elétrica gerada pelos painéis solares",
        });
      }
    });
    this.updateSolarData();
  };

  getLast24hrs = () => {
    this.chartLoaded = false;
    this.Dashboard.getDayProduction(this.day).then((r) => {
      r = r.filter((t) => moment.utc(t.date).isSame(this.day, "day"));
      // this.createLast24HrsChart(r);
      this.createChart(r);
      this.chartLoaded = true;
    });
  };

  // Output an object with data for chart and other relevant info for background colors
  createDayData = (origin, colors, tints) => {
    let d = [[], [], []]; // For values and their costs
    let c = [[], [], []];
    let colorIndex = 0;
    for (let i = 0; i < origin.length; i++) {
      let m = moment.utc(origin[i].date);
      let index = m.hour() * 4 + m.minute() / 15;
      d[0][index] = origin[i].consumption;
      c[0][index] = origin[i].costConsumption;
      d[1][index] = origin[i].production;
      c[1][index] = origin[i].costProduction;
      d[2][index] = origin[i].return;
      c[2][index] = origin[i].costReturn;
    }

    // If we don't have data for this hour, use old data
    // Find index of the HH:mm that has the same register in the previous day
    let m = moment.utc();
    let index = m.hour() * 4 + m.minute() / 15;
    if (moment().minute() < 15 && moment().hour() < m.hour()) {
      colorIndex = index;
    } else {
      colorIndex = index + 1;
    }

    let backgroundColors = [[], [], []];
    for (let i = 0; i < 96; i++) {
      if (i < colorIndex) {
        backgroundColors[0][i] = colors[0];
        backgroundColors[1][i] = colors[1];
        backgroundColors[2][i] = colors[1];
      } else {
        backgroundColors[0][i] = tints[0];
        backgroundColors[1][i] = tints[1];
        backgroundColors[2][i] = tints[1];
      }
    }

    return {
      data: d,
      cost: c,
      backgroundColors: backgroundColors,
    };
  };

  createFullDayLabels = (timestamp) => {
    let l = [];
    let time = moment.utc(this.day).startOf("day");
    for (let i = 0; i < timestamp; i++) {
      l.push(time.format("YYYY-MM-DD HH:mm"));
      time.add(15, "minute");
    }
    return l;
  };

  createChart = (origin) => {
    let max;
    if (origin.length > 0) max = origin[0].max || 100;
    let index =
      moment.utc(origin[origin.length - 1].date).hour() * 4 +
      moment.utc(origin[origin.length - 1].date).minute() / 15;
    index += 1;
    let labels = this.createFullDayLabels(index);
    let datasets = [];
    let series = ["Rede", "Solar consumido", "Excedente", "Consumo total"];
    let colors = ["#AA3033", "#FDA50F", "#512DA8", "#01419A"];
    let tints = ["#AA303380", "#FDA50F80", "#512DA880", "#01419A"];

    series = series.reverse();
    colors = colors.reverse();
    tints = tints.reverse();

    let dayData = this.createDayData(origin, colors, colors);
    let data = dayData.data;
    if (!this.hasSolar) {
      series = [series[0]];
      colors = [colors[0]];
      tints = [tints[0]];
      data = [data[0]];
    } else {
      // Fix 27/09/2022 - Solar está produção bruta, deve ser valor liquido
      data.forEach((r, i) => {
        if (i == 1) {
          r.forEach((x, j) => {
            if (data[1][j] < 0) {
              data[1][j] = 0;
            }
            if (data[2][j] > 0) {
              data[2][j] = 0;
            }
            // Excedente vem negativo, então "soma";
            data[1][j] =
              Math.round((x + data[2][j] + Number.EPSILON) * Math.pow(10, 3)) /
              Math.pow(10, 3);
            if (data[1][j] < 0) {
              data[1][j] = 0;
            }
          });
        }
      });
      // Adição de "consumo total"
      data.push([]);
      data.forEach((r, i) => {
        if (i < 2) {
          r.forEach((x, j) => {
            let value = data[0][j] + data[1][j];
            data[3][j] =
              Math.round((value + Number.EPSILON) * Math.pow(10, 3)) /
              Math.pow(10, 3);
          });
        }
      });
    }

    data = data.reverse();

    // For line charts, we adjust like this
    data.forEach((r, i) => {
      datasets.push({
        labels: series[i],
        fill: i == 0 ? false : true,
        strokeColor: colors[i],
        lineTension: 0.2,
        borderWidth: i == 0 ? 2 : 0,
        backgroundColor: tints[i],
        borderColor: colors[i],
        pointBorderColor: colors[i],
        pointBackgroundColor: colors[i],
        pointRadius: 0.2,
        pointHoverBorderColor: colors[i],
        pointHoverBackgroundColor: colors[i],
        pointHoverRadius: 0.2,
      });
    });

    this.chart = {
      labels: labels,
      datasets: datasets,
      data: data,
      series: series,
      options: {
        layout: {
          padding: {
            left: 20,
            right: 20,
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            generateLabels: (chart) => {
              labels =
                Chart.defaults.global.legend.labels.generateLabels(chart);
              for (let key in labels) {
                labels[key].fillStyle = colors[key];
                labels[key].strokeStyle = colors[key];
              }
              return labels;
            },
          },
        },
        tooltips: {
          position: "nearest",
          intersect: false,
          callbacks: {
            labelColor: (tooltipItem, data) => {
              return {
                borderColor: colors[tooltipItem.datasetIndex],
                backgroundColor: colors[tooltipItem.datasetIndex],
              };
            },
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              let value;
              if (dataset.label === "Excedente")
                // Show tooltip for Excedente with positive value even though the actual value is negative
                value = -dataset.data[tooltipItem.index];
              else value = dataset.data[tooltipItem.index];
              return dataset.label + ": " + value;
            },
          },
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time", // add this!
              parser: "DD/MM HH:mm",
              time: {
                unit: "hour",
                displayFormats: {
                  hour: "HH:mm",
                },
              },
            },
          ],
          yAxes: [
            {
              id: "kW",
              stacked: false,
              position: "left",
              gridLines: {
                display: true,
              },
              scaleLabel: {
                display: false,
              },
              display: true,
              ticks: {
                maxTicksLimit: 10,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return (
                    Math.round((value + Number.EPSILON) * Math.pow(10, 2)) /
                    Math.pow(10, 2)
                  );
                },
              },
            },
          ],
        },
      },
    };
  };
}

DashboardInstantController.$inject = [
  "$state",
  "$scope",
  "$interval",
  "$timeout",
  "UIService",
  "DashboardService",
  "AuthenticationService",
];
